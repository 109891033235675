import { Box, ScreenSize } from 'glints-aries';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const ContactContainer = styled.div`
  padding: 24px 24px 0px 24px;

  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 20px 16px 0px 20px;
  }
`;

export const ItemsContactContainer = styled(Box)`
  & + & {
    margin-top: 32px;
  }
  &:first-child:not(:only-child) {
    margin-bottom: 32px;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    & + & {
      margin-top: 24px;
    }
    &:first-child:not(:only-child) {
      margin-bottom: 24px;
    }
  }
`;

export const SocialSitesWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  @media (max-width: ${ScreenSize.mobileL}px) {
    flex-direction: column;
    row-gap: 12px;
  }
`;

export const SocialItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  color: ${Blue.S99};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;
