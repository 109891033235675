import React from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { FormattedMessage } from 'react-intl';

import { parseAddressMapURL } from 'src/common/helpers';

import * as S from '../../CompanyPage.sc';

interface Props {
  companyAddress: string;
}

export const CompanyAddressSection: React.FC<
  React.PropsWithChildren<Props>
> = ({ companyAddress }) => {
  return (
    <>
      <S.TitleSection>
        <FormattedMessage
          id="company-page.overview-section.address.title"
          defaultMessage="Office Address"
        />
      </S.TitleSection>
      <Typography variant="subtitle2">{companyAddress}</Typography>
      <S.LocationMap>
        <Icon name="ri-map-line" />
        <a
          href={parseAddressMapURL(companyAddress)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="button">
            <FormattedMessage
              id="company-page.overview-section.view-on-map.title"
              defaultMessage="View On Map"
            />
          </Typography>
        </a>
      </S.LocationMap>
    </>
  );
};
